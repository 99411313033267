@import '../common';

.pvt-table {
    .gridjs-wrapper {
        box-shadow: none; }
    .gridjs-thead {
        background-color: transparent; }
    .gridjs-tbody {
        background-color: transparent; }
    td.gridjs-td {
        background: transparent;
        border-left: 0;
        border-right: 0;
        // font-size: 0.814rem
        line-height: 1rem;
        color: #707683;
        padding: 1.25rem 0;
        &:last-of-type {
            text-align: right; } }
    td.gridjs-td,
    th.gridjs-th {
        background: transparent;
        border-left: 0;
        border-right: 0;
        line-height: 1rem;
        color: #707683;
        padding: 1.25rem 0; }
    th.gridjs-th {
        border: 1px solid var(lightGray);
        border-top: none;
        padding: 14px 24px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: content-box;
        white-space: nowrap;
        outline: none;
        &:last-of-type {
            text-align: right; } }
    .gridjs-summary {
        display: none; }
    .gridjs-footer {
        box-shadow: none;
        background: none; }
    .gridjs-pagination .gridjs-pages {
        button,
        button[disabled] {
            padding: 0.5rem 1rem;
            background-color: transparent; } } }

@media(max-width: $screen-lg) {
    .pvt-table {
        .gridjs-wrapper {
            overflow-x: auto; }
        .gridjs-table {
            table-layout: initial; }
        td.gridjs-td {
            min-width: 150px; } } }
