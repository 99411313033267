@import '../../components/common';

.dsar-form {
    margin: 0 auto;
    width: calc(100% - 24px);
    padding: 40px; }

.pvt-yourrequest-subtitle {
    color: var(--gray);
    font-size: 1.3rem;
    padding-bottom: 20px; }

.dsar-file-space {
    margin-right: 20px; }

.dsar-filename {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: var(--txt-surface);
    width: 100%;
    display: block;
    user-select: none;
    text-transform: capitalize; }

.dsar-file-label {
    margin-bottom: 10px; }

.dsar-label-rules {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: var(--txt-surface);
    width: 100%;
    display: initial;
    user-select: none;
    text-transform: none; }

.dsar-button-upload {
    display: inline-table;
    align-items: center;
    padding: .75em 1em;
    margin-right: 15px;
    border: none;
    color: var(--primaryColor);
    border-radius: .5rem;
    background-color: var(--grayLight);
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    box-shadow: 0px 2px 0px #0000001A;
    outline: none;
    text-decoration: none;
    &:hover {
        cursor: pointer;
        filter: brightness(110%); }
    img {
        max-height: 24px;
        margin-bottom: -5px;
        margin-right: 10px; } }

.dsarFileTip {
    font-size: 0.7rem;
    color: gray;
    display: block; }

.dsarFileError {
    font-size: 0.8rem;
    color: red;
    display: block; }

.popup {
    padding: 10px;
    text-align: center; }

.close {
    a {
        margin-left: 95%;
        display: block;
        width: 14px;
        height: 14px;
        font-weight: bold; }

    a:hover {
        cursor: pointer; } }

.popup-content {
    max-height: 250px;
    top: 50%;
    position: initial !important; }

.pvt-your-request-link {
    cursor: pointer;
    color: var(--primaryColor);
    font-weight: bold; }

.pvt-request-detail {
    .pvt-col {
        text-align: left; }

    .pvt-row {
        flex-wrap: wrap; } }

.pvt-request-detail-replies {
    .title {
        font-size: 17px;
        font-weight: bold !important; }

    .pvt-request-detail-reply-container {
        background-color: #fcfcfc;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 15px;

        .reply-message, .sender {
            flex-grow: 1; }

        .reply-message {
            margin-top: 15px; }

        .reply-attachment {
            flex-grow: 1;
            margin-top: 25px; }

        .sender label {
            font-size: 1rem;
            margin-bottom: 0.5rem;
            font-weight: 300;
            color: var(--txt-surface);
            -webkit-user-select: none;
            user-select: none; } }

    .download-link {
        cursor: pointer;
        text-decoration: underline;
        color: blue; } }

@media(max-width: $screen-lg) {
    .popup-content {
        max-height: 50vh;
        min-height: 120px;
        top: 25vh;
        width: 75vw !important; } }

.pvt-btn {
    display: flex !important;
    justify-content: center; }

.pvt-txt-card {
    display: table-cell;
    width: auto;
    height: auto;
    padding: 0px !important; }

.pvt-card-100 {
    width: 100%;
    display: table;
    margin-bottom: 40px; }

.pvt-table td.gridjs-td {
    background: transparent;
    border-left: 0;
    border-right: 0;
    line-height: 1rem;
    color: #707683;
    padding: 1.25rem 0; }

.pvt-label-justify-reject {
    margin: 10%; }

.dsar-selector {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 2px solid var(--grayLight) !important;
  font-size: 18px !important;
  color: var(--txt-surface) !important;
  background: #fff; }

