@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
:root {
  --bgColorOriginal: #f8f8fb;
  --bgColorTint: #EBF6FF;
  --bgColor: #f8f8fb;
  --bgCard: #fff; /* alterar nova cor */
  --surface: #fff;
  --gray: #828B96; /* alterar */
  --grayMidium: #828B96; /* alterar nova cor */
  --grayLight: #E9E9E9; /* alterar nova cor */
  --primaryColor: #259cd3;
  --primaryLight: #3d9cff;
  --success: #CDF3E7; /* alterar nova cor */
  --disclaimer: #DFF1FF; /* alterar nova cor */
  --warning: #FFFED9; /* alterar nova cor */
  --error: #f42623;
  --tagConcluded: #828B96; /* alterar nova cor */
  --tagOpened: #3DC26E; /* alterar nova cor */
  --tagPendent: #E9D130; /* alterar nova cor */
  --tagDenied: #EF6767; /* alterar nova cor */
  --confirm: #29CC97;
  --enter: cubic-bezier(0, 0.5, 0.5, 1);
  --move: cubic-bezier(0.5, 0, 0.5, 1);
  --exit: cubic-bezier(0.5, 0, 0, 1);
  --dk-surface: #53626d;
  --dk-surface-75: rgba(37, 56, 71, 0.76);
  --dk-background-05: rgba(83, 98, 109, 0.05);
  --lg-surface: #fff;
  --lg-surface-grayish: #eaecee;
  --toggle: #29CC97;
  --toggleMidium: #21A379; /* alterar nova cor */
  --base: 16;
  --confirmColor: #29cc97;
  --txt-surface: #4D4F5C;
  --text-dark_background: #fff
}
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  height: 100%;
}
*, :after, :before {
  box-sizing: inherit
}
body, h1, h2, h3, h4, h5, h6, ol, p, ul {
  margin: 0;
  padding: 0;
  font-weight: 400
}
p {
  line-height: 140%
}
ol, ul {
  list-style: none
}
/* Alterar - remover
img {
  max-width: 100%;
  height: auto
}
*/
#privacy-tools-app {
  background: #f8f8fb;
  background: var(--bgColor);
  min-height: 100vh;
  min-width: 100%;
  display: flex
}
.creteria-pass {
  margin-bottom: 15px;
  padding: 20px;
  width: 100%;
  color: var(--grayMidium);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px
}
.mt-10 {
  margin-top: 10px
}
.mt-20 {
  margin-top: 20px
}
.mt-30 {
  margin-top: 30px
}
/* alterar novo */
.ml-10 {
  margin-left: 10px;
}
/* alterar novo */
.ml-20 {
  margin-left: 20px;
}
/* alterar novo */
.mr-10 {
  margin-right: 10px;
}
/* alterar novo */
.mr-20 {
  margin-right: 20px;
}
/* alterar novo */
.mb-10 {
  margin-bottom: 10px;
}
/* alterar novo */
.mb-20 {
  margin-bottom: 20px;
}
/* alterar novo */
.txt-al-center {
  text-align: center
}
/* alterar novo */
.txt-side-title {
  margin-left: 10px;
  font-size: 25px
}
/* alterar novo */
.pvt-route-content section {
  overflow-x: auto
}
/* alterar novo */
/* @media (max-width:624px) {
  .pvt-route-content {
    margin-top: 95px;
  }
} */
/* alterar novo */
.pvt-h2-divisor {
  color: var(--gray);
  display: flex;
  flex-direction: row;
  margin: 40px 0px;
}
/* alterar novo */
.pvt-h2-divisor:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--grayLight);
  margin: auto auto auto 20px;
}
/* alterar */
.pvt-btn {
  display: block;
  align-items: center;
  padding: .75em 1em;
  border: none;
  background: var(--primaryColor);
  border-radius: .5rem;
  color: var(--surface);
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  box-shadow: 0px 2px 0px #0000001A;
  outline: none;
  text-decoration: none
}
.pvt-btn:disabled {
  pointer-events: none;
  opacity: .3
}
/* alterar novo */
.pvt-btn2 {
  display: block;
  align-items: center;
  padding: .75em 1em;
  border: none;
  color: var(--primaryColor);
  background-color: transparent;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  outline: none;
  text-decoration: none
}
.pvt-btn3 {
  display: block;
  align-items: center;
  padding: .75em 1em;
  border: none;
  color: var(--primaryColor);
  border-radius: .5rem;
  background-color: var(--surface);
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  box-shadow: 0px 2px 0px #0000001A;
  outline: none;
  text-decoration: none
}
.pvt-btn:hover, .pvt-btn2:hover, .pvt-btn3:hover {
  filter: brightness(110%);
}
.pvt-btn:active {
  transform: translate3d(0, 1px, 0)
}
.pvt-btn-default, .pvt-btn-primary {
  --color: var(--surface);
  --background: var(--primaryColor)
}
.pvt-btn-confirm {
  --color: var(--surface);
  --background: var(--confirmColor)
}
.pvt-btn-cancel {
  --color: #fff;
  --background: #6c757d
}
.pvt-btn-danger {
  --color: var(--surface);
  --background: var(--error)
}
.pvt-btn-ghost {
  --color: var(--gray);
  --background: transparent;
  text-decoration: underline
}
.pvt-btn.lighter {
  --color: var(--surface);
  font-weight: 300
}
.pvt-btn.invert svg {
  margin-right: 0;
  margin-left: .75rem;
  order: 2
}
.pvt-btn.full {
  display: block;
  padding: 1rem;
  width: 100%;
  text-align: center
}
.pvt-btn.size-small {
  font-size: .8rem
}
.pvt-btn svg {
  margin-right: .75rem;
  height: 24px;
  width: 24px;
  opacity: .2;
  color: #000
}
.pvt-switch {
  position: relative;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  --travel: 15px;
  --text: 0.75em;
  --base: 16px
}
.pvt-accordion-header .pvt-switch {
  margin: 0
}
.pvt-switch-large {
  --travel: 18px;
  --base: 19px;
  --text: 0.75em
}
.pvt-switch-large .pvt-switch-text {
  font-style: italic
}
.pvt-switch-text {
  font-weight: 700;
  color: var(--dk-surface);
  font-size: var(--text);
  margin-right: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.pvt-switch-checkbox {
  display: none
}
.pvt-switch-checkbox:checked + label:before {
  transform: translate3d(var(--travel), 1px, 0);
  background: var(--primaryColor)
}
.pvt-switch-checkbox:checked + label:active:before {
  transform-origin: top right;
  transform: translate3d(var(--travel), 1px, 0) scaleX(1.2)
}
.pvt-switch-label {
  position: relative;
  top: 1px;
  height: calc(var(--base) + 2px);
  width: calc(var(--base)*2);
  display: block;
  border-radius: calc(var(--travel) + 4px);
  border: 1px solid var(--dk-surface);
  cursor: pointer
}
.pvt-switch-label:before {
  position: absolute;
  transform: translate3d(1px, 1px, 0);
  content: "";
  width: calc(var(--base) - 2px);
  height: calc(var(--base) - 2px);
  border-radius: 100%;
  background-color: var(--dk-surface);
  transition: transform .2s cubic-bezier(0, 0, .2, 1), background .3s cubic-bezier(0, 0, .2, 1)
}
.pvt-switch-label:active:before {
  transform-origin: top left;
  transform: translate3d(1px, 1px, 0) scaleX(1.2)
}
.pvt-accordion {
  position: relative;
  border-top: 1px solid rgba(83, 98, 109, .14)
}
.pvt-accordion:first-of-type {
  border-top: none
}
.pvt-accordion-header {
  position: relative;
  padding: .75rem 0;
  color: var(--gray);
  cursor: pointer;
  display: flex;
  align-items: center
}
.pvt-accordion-header.toggled i {
  font-weight: 700
}
.pvt-accordion-header > svg {
  margin: 4px 8px 0 -4px;
  height: 20px;
  width: 25px
}
.pvt-accordion-content {
  padding: 0 0 0 2rem;
  color: var(--gray);
  box-sizing: border-box;
  overflow: hidden
}
/* alterar */
.pvt-accordion-content p {
  margin: 0 0 1rem;
  font-size: .75rem;
  line-height: 1.4em
}
.pvt-accordion-content .pvt-switch, .pvt-accordion-header .pvt-switch {
  position: absolute;
  top: .75rem;
  right: 0
}
.pvt-row {
  display: flex
}
.pvt-row.x-center {
  justify-content: center
}
.pvt-row.x-end {
  justify-content: flex-end
}
.pvt-row.x-between {
  justify-content: space-between
}
.pvt-row.y-start {
  align-items: flex-start
}
.pvt-row.y-center {
  align-items: center
}
/* alterar novo */
.pvt-d-block {
  display: block
}
/* alterar novo */
.pvt-f-left {
  float: left
}
/* alterar novo */
.pvt-f-right {
  float: right
}
.pvt-row > h1 {
  margin: 0;
  font-size: 1.8em
}
.pvt-col {
  display: block
}
@media (max-width:624px) {
  .pvt-col, .pvt-row {
    font-size: 12px
  }
}
/* alterar */
.pvt-notlogged {
  padding: 2rem;
  background: var(--bgColorTint);
  box-shadow: 0px 2px 6px #0000000A;
  border-radius: 10px;
  text-align: center;
  color: black;
  max-width: 460px
}
/* alterar */
.pvt-notlogged-wrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  /* background-image: url(../img/notlogged.png); */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain
}
/* alterar */
.pvt-notlogged h3 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 1rem
}
.pvt-notlogged i {
  font-size: 50px;
  color: var(--primaryColor);
  margin-bottom: 20px
}
.pvt-notlogged .pvt-row {
  margin-top: 2rem
}
.pvt-notlogged .pvt-row button {
  margin: 0 1rem
}
.pvt-icon {
  height: 20px;
  width: 20px
}
/* alterar novo */
ul.pvt-list-icons li {
  width: 47%;
  display: inline-table;
  margin-bottom: 40px;
  vertical-align: text-top
}
/* alterar novo */
ul.pvt-list-icons li:nth-child(even) {
  margin-left: 5%
}
/* alterar novo */
.pvt-list-icon {
  width: 100px
}
/* alterar novo */
ul.pvt-list-icons li figure {
  background: transparent linear-gradient(144deg, var(--toggle) 0%, var(--toggleMidium) 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 2px #0000001C;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  width: 100px;
  height: 100px;
  line-height: 100%;
  vertical-align: middle;
  text-align: center;
  display: block;
  color: var(--surface);
  font-size: 45px;
  padding-top: 25px;
  margin: 0px;
}
/* alterar novo */
ul.pvt-list-icons li div {
  padding-left: 15px;
  vertical-align: top;
  display: table-cell;
}
/* alterar novo */
ul.pvt-list-icons li div:nth-child(1) {
  padding-left: 0px;
}
/* alterar novo */
ul.pvt-list-icons li div h3 {
  margin-bottom: 20px;
  font-weight: bold
}
/* alterar novo */
@media only screen and (max-width: 992px) {
  ul.pvt-list-icons li {
    width: 100%;
  }
  ul.pvt-list-icons li:nth-child(even) {
    margin-left: 0%
  }
}
/* alterar */
.pvt-preference {
  padding: 40px;
  margin-bottom: 24px
}
/* alterar remover
.pvt-preference:nth-child(2n) {
  border-radius: 4px;
  background: rgba(83, 98, 109, .03)
}
*/
.pvt-preference h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 20px;
  color: var(--txt-surface)
}
.pvt-preference .pvt-accordion {
  margin: 0 .5rem
}
.consent-title {
  max-width: 80%;
  margin-right: 40px;
  display: block
}
.pvt-yourrequest-subtitle {
  color: var(--gray);
  font-size: 1.3rem;
  padding-bottom: 20px
}
/* alterar */
.dsar-file-space {
  margin-right: 20px
}
.dsar-filename {
  font-size: 1rem;
  margin-bottom: .5rem;
  font-weight: 500;
  color: var(--txt-surface);
  width: 100%;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize
}
.dsar-file-label {
  margin-bottom: 10px
}
/* alterar */
.dsar-button-upload {
  display: inline-table;
  align-items: center;
  padding: .75em 1em;
  margin-right: 15px;
  border: none;
  color: var(--primaryColor);
  border-radius: .5rem;
  background-color: var(--grayLight);
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  box-shadow: 0px 2px 0px #0000001A;
  outline: none;
  text-decoration: none
}
.dsar-button-upload:hover {
  cursor: pointer;
  filter: brightness(110%)
}
.dsar-button-upload img {
  max-height: 24px;
  margin-bottom: -5px;
  margin-right: 10px
}
.dsarFileTip {
  font-size: 15px;
  color: grey;
  display: inline-table
}
.dsarFileError {
  font-size: .8rem;
  color: red;
  display: block
}
.popup {
  padding: 10px;
  text-align: center
}
.popup .close a {
  margin-left: 95%;
  display: block;
  width: 14px;
  height: 14px;
  font-weight: 700
}
.popup .close a:hover {
  cursor: pointer
}
.popup-content {
  max-height: 250px;
  top: 50%;
  position: static !important
}
/* alterar */
.pvt-your-request-link {
  cursor: pointer;
  color: var(--primaryColor);
  font-weight: bold
}
/* alterar novo */
.pvt-request-detail, .pvt-request-detail-replies {
  display: block !important
}
/* alterar novo */
.pvt-request-detail {
  margin-bottom: 40px
}
.pvt-request-detail .pvt-col {
  text-align: left;
  display: table-cell
}
/* alterar novo */
.pvt-request-detail .pvt-row {
  color: var(--gray);
  flex-wrap: wrap;
  display: table;
  padding: 5px 0px 5px 0px;
  width: 100%;
}
/* alterar */
.pvt-request-detail-replies .title {
  font-size: 17px;
  font-weight: bold
}
.pvt-request-detail-replies .pvt-request-detail-reply-container {
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px
}

.your-requests-table {
  width: 88%;
}
.pvt-request-detail-replies .pvt-request-detail-reply-container .reply-message, .pvt-request-detail-replies .pvt-request-detail-reply-container .sender {
  flex-grow: 1
}
.pvt-request-detail-replies .pvt-request-detail-reply-container .reply-message {
  margin-top: 15px
}
.pvt-request-detail-replies .pvt-request-detail-reply-container .reply-attachment {
  flex-grow: 1;
  margin-top: 25px
}
.pvt-request-detail-replies .pvt-request-detail-reply-container .sender label {
  font-size: 1rem;
  margin-bottom: .5rem;
  font-weight: 300;
  color: var(--txt-surface);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.pvt-request-detail-replies .download-link {
  cursor: pointer;
  text-decoration: underline;
  color: #00f
}
@media (max-width:744px) {
  .popup-content {
    max-height: 50vh;
    min-height: 120px;
    top: 25vh;
    width: 75vw !important
  }
}
.pvt-table .gridjs-wrapper {
  box-shadow: none
}
.pvt-table .gridjs-tbody, .pvt-table .gridjs-thead {
  background-color: transparent
}
/* alterar */
.pvt-table td.gridjs-td {
  background: transparent;
  border-left: 0;
  border-right: 0;
  line-height: 1rem;
  color: #707683;
  padding: 1.25rem 0
}
.pvt-table td.gridjs-td:last-of-type {
  text-align: right
}
.pvt-table td.gridjs-td, .pvt-table th.gridjs-th {
  padding-left: 0;
  padding-right: 0
}
/* alterar */
.pvt-table th.gridjs-th {
  border: none;
  color: var(--txt-surface) !important;
  font-weight: bold
}
.pvt-table th.gridjs-th:last-of-type {
  text-align: right
}
.pvt-table .gridjs-summary {
  display: none
}
.pvt-table .gridjs-footer {
  box-shadow: none;
  background: none
}
.pvt-table .gridjs-pagination .gridjs-pages button, .pvt-table .gridjs-pagination .gridjs-pages button[disabled] {
  padding: .5rem 1rem;
  background-color: transparent
}
@media (max-width:744px) {
  .pvt-table .gridjs-wrapper {
    overflow-x: auto
  }
  .pvt-table .gridjs-table {
    table-layout: auto
  }
  .pvt-table td.gridjs-td {
    min-width: 150px
  }
}
.gridjs-footer button, .gridjs-head button {
  cursor: pointer;
  background-color: transparent;
  background-image: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none
}
.gridjs-head {
  margin-bottom: 5px;
  padding: 5px 1px
}
.gridjs-head:after {
  content: "";
  display: block;
  clear: both
}
.gridjs-head:empty {
  padding: 0;
  border: none
}
.gridjs-container {
  overflow: hidden;
  display: inline-block;
  padding: 2px;
  color: #000;
  position: relative;
  z-index: 0
}
/* alterar */
.gridjs-footer {
  display: block;
  position: relative;
  z-index: 5;
  padding: 12px 24px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .26);
  border-radius: 0 0 10px 10px;
  border-bottom-width: 1px;
  border-color: #e5e7eb;
  border-top: 1px solid #e5e7eb
}
.gridjs-footer:empty {
  padding: 0;
  border: none
}
input.gridjs-input {
  outline: none;
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-radius: 5px;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 1.45;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}
input.gridjs-input:focus {
  box-shadow: 0 0 0 3px rgba(149, 189, 243, .5);
  border-color: #9bc2f7
}
button.gridjs-sort {
  float: right;
  height: 24px;
  width: 16px;
  background-repeat: no-repeat
}
button.gridjs-sort-neutral {
  opacity: .3;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAr0lEQVQ4T6WTUQ5BQQxFz1uG4IdnH2xHgo3w/xKLYSOEiMQqkCatSE2nwfy297Rz70zDn6dJ9APgAVyjvhpgAuyAOzADDiVIBDBxX0WXCFICeLENLkI8oAX2gE32WwtkChyt4AFzYJkYuwG2EeDrULMYU6AHLIBVoloDXXSFkZo4DCBnNfFU82CsD8hDPsQCiTzwkKK4BpCaQeQpS/avtd+vl6XQ0890++UzpRFKwxNZzCARuMPL3wAAAABJRU5ErkJggg==");
  background-position-y: center
}
button.gridjs-sort-asc {
  padding-bottom: 6px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAaUlEQVQ4T+3SsQ1AUBQF0GMfQ5hEoddILKHR6VT2MIZtJBoSkf/9iE68+t1T3NzMy8te5v2AZAc1Vgyxsu9KbNHtwQZ9CIkB5/CRCyIhIBSOIlegwpgYV4np+LkCOYoEMGOJAY+X/YEpbzl7DBHJyXtQAAAAAElFTkSuQmCC");
  background-position-y: top
}
button.gridjs-sort-desc {
  padding-top: 6px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAc0lEQVQ4T2NkoBAwUqifYRgaIMPAwGBAIFzOMzAwPIWpQQ+DYgYGhh4CBuQzMDBMwmUAEwMDw1QGBoYMHIaA5HIZGBj+4zIAJI7LEAzNIMW4ohHdEKya8RkAc0kvAwPDHwYGhjJkZyN7bxgmJJLzFsVhAAAclRAR/sEwnwAAAABJRU5ErkJggg==");
  background-position-y: bottom
}
button.gridjs-sort:focus {
  outline: none
}
.gridjs-pagination {
  color: #3d4044
}
.gridjs-pagination:after {
  content: "";
  display: block;
  clear: both
}
.gridjs-pagination .gridjs-summary {
  float: left;
  margin-top: 5px
}
.gridjs-pagination .gridjs-pages {
  float: right
}
.gridjs-pagination .gridjs-pages button {
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-right: none;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.gridjs-pagination .gridjs-pages button:focus {
  box-shadow: 0 0 0 2px rgba(149, 189, 243, .5)
}
.gridjs-pagination .gridjs-pages button:hover {
  background-color: #f7f7f7;
  color: #3c4257;
  outline: none
}
.gridjs-pagination .gridjs-pages button:disabled, .gridjs-pagination .gridjs-pages button:hover:disabled, .gridjs-pagination .gridjs-pages button[disabled] {
  cursor: default;
  background-color: #fff;
  color: #6b7280
}
.gridjs-pagination .gridjs-pages button.gridjs-spread {
  cursor: default;
  box-shadow: none;
  background-color: #fff
}
.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: #f7f7f7;
  font-weight: 700
}
.gridjs-pagination .gridjs-pages button:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  border-right: 1px solid #d2d6dc
}
.gridjs-pagination .gridjs-pages button:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px
}
/* alterar */
table.gridjs-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  display: table;
  margin: 0;
  padding: 0;
  overflow: auto;
  table-layout: fixed
}
.gridjs-tbody, td.gridjs-td {
  background-color: #fff
}
td.gridjs-td {
  border: 1px solid #e5e7eb;
  padding: 12px 24px;
  box-sizing: content-box
}
td.gridjs-td:first-child {
  border-left: none
}
td.gridjs-td:last-child {
  border-right: none
}
td.gridjs-message {
  text-align: center
}
/* alterar */
th.gridjs-th {
  border: 1px solid var(lightGray);
  border-top: none;
  padding: 14px 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: content-box;
  white-space: nowrap;
  outline: none
}
th.gridjs-th-sort {
  cursor: pointer
}
th.gridjs-th-sort:focus, th.gridjs-th-sort:hover {
  background-color: #e5e7eb
}
th.gridjs-th-fixed {
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0 1px 0 0 #e5e7eb
}
@supports (-moz-appearance:none) {
  th.gridjs-th-fixed {
    box-shadow: 0 0 0 1px #e5e7eb
  }
}
th.gridjs-th:first-child {
  border-left: none
}
th.gridjs-th:last-child {
  border-right: none
}
.gridjs-tr {
  border: none
}
.gridjs-tr:last-child td {
  border-bottom: 0
}
.gridjs *, .gridjs :after, .gridjs :before {
  box-sizing: border-box
}
/* alterar */
.gridjs-wrapper {
  position: relative;
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .26);
  border-radius: 8px 8px 0 0;
  display: block;
  border-top-width: 1px;
  border-color: #e5e7eb
}
.gridjs-wrapper:last-of-type {
  border-radius: 8px;
  border-bottom-width: 1px
}
.gridjs-search {
  float: left
}
.gridjs-search-input {
  width: 250px
}
.gridjs-loading-bar {
  z-index: 10;
  background-color: #fff;
  opacity: .5
}
.gridjs-loading-bar, .gridjs-loading-bar:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0
}
.gridjs-loading-bar:after {
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, hsla(0, 0%, 80%, 0), hsla(0, 0%, 80%, .2) 20%, hsla(0, 0%, 80%, .5) 60%, hsla(0, 0%, 80%, 0));
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: ""
}
@-webkit-keyframes shimmer {
  to {
    transform: translateX(100%)
  }
}
@keyframes shimmer {
  to {
    transform: translateX(100%)
  }
}
.pvt-label {
  background: var(--background);
  color: var(--color);
  padding: .3rem 1rem;
  border-radius: 30px;
}
/* alterar */
.pvt-label-concluded {
  --background: var(--tagConcluded);
  --color: var(--surface)
}
/* alterar */
.pvt-label-opened {
  --background: var(--tagOpened);
  --color: var(--surface)
}
/* alterar */
.pvt-label-pendent {
  --background: var(--tagPendent);
  --color: var(--txt-surface);
}
/* alterar */
.pvt-label-denied {
  --background: var(--tagDenied);
  --color: var(--surface)
}
.your-requests-table .pvt-label {
  float: right;
}
/* alterar */
.pvt-color-concluded {
  color: var(--tagConcluded);
}
/* alterar */
.pvt-color-opened {
  color: var(--tagOpened);
}
/* alterar */
.pvt-color-pendent {
  color: var(--tagPendent);
}
/* alterar */
.pvt-color-denied {
  color: var(--tagDenied);
}
/* alterar */
.dsar-label {
  font-size: 1rem;
  margin-bottom: .5rem;
  font-weight: bold;
  color: var(--txt-surface);
  width: 100%;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.dsar-mandatory:after {
  content: " *";
  color: red
}
/* alterar */
.dsar-field-wrapper {
  margin: 0 0 25px;
  position: relative
}
.dsar-button {
  width: 180px;
  height: 55px;
  background: var(--confirmColor);
  border: none;
  border-radius: .5rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: .05s box-shadow var(--move);
  font-family: inherit
}
.dsar-button:hover {
  transform: translate3d(0, -1px, 0)
}
.dsar-button:active {
  transform: translate3d(0, 1px, 0)
}
.dsar-textarea {
  margin: 0;
  outline: none;
  padding: 12px;
  min-height: 180px;
  resize: vertical;
  line-height: 1.2
}
.textarea-wrapper {
  position: relative
}
.textarea-wrapper > svg {
  stroke: var(--error);
  position: absolute;
  right: 10px;
  top: 19px
}
.overlay {
  position: fixed;
  background: #222;
  opacity: .5
}
.overlay, .overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}
.overlay__inner {
  position: absolute
}
.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%)
}
.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-radius: 100%;
  border: 2px solid hsla(0, 0%, 100%, .05);
  border-top-color: #fff
}
@-webkit-keyframes spin {
  to {
    transform: rotate(1turn)
  }
}
@keyframes spin {
  to {
    transform: rotate(1turn)
  }
}
/* alterar */
.pvt-alert {
  font-size: 1rem;
  line-height: 1.4em;
  color: var(--txt-surface);
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: .5rem;
  max-width: 440px
}
.pvt-alert > p {
  margin: 0;
  font-style: normal
}
/* alterar remover
.pvt-alert-error, .pvt-alert-success {
  margin-bottom: 12px;
  font-size: 14px;
  padding-left: 1em;
  padding-right: 1em;
  line-height: 1.2em
}
*/
/* alterar */
.pvt-alert-error {
  --background: var(--error);
}
/* alterar */
.pvt-alert-success {
  background: var(--success)
}
/* alterar */
.pvt-alert-disclaimer {
  background: var(--disclaimer)
}
/* alterar */
.pvt-alert-warning {
  background: var(--warning)
}
/* alterar */
.pvt-alert-confirm {
  background: var(--success)
}
.pvt-alert-full {
  max-width: 100%
}
.pvt-alert-center {
  text-align: center
}
.pvt-alert-center p {
  margin: 0 auto
}
.pvt-accordion-content .pvt-alert {
  margin-bottom: 1rem
}
.pvt-alert svg {
  position: relative;
  margin-right: 4px;
  color: inherit;
  height: 20px;
  width: 20px
}
.dsar-input {
  outline: none;
  transition: all .3s ease-in-out;
  padding: 0 12px
}
.dsar-input:focus {
  background-color: #fff;
  border-color: rgba(61, 156, 255, .4);
  box-shadow: 0 0 0 4px rgba(61, 156, 255, .1)
}
.dsar-input.error {
  border-color: rgba(234, 76, 137, .4);
  box-shadow: 0 0 0 4px rgba(234, 76, 137, .1)
}
/* alterar novo */
.css-pwshrn-control {
  -webkit-box-align: center;
  align-items: center;
  background-color: var(--surface);
  border: 2px solid var(--grayLight);
  border-radius: 5px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  height: 50px;
  outline: 0px !important;
}
/* alterar novo */
.css-pwshrn-control div {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
/* alterar novo */
.css-pwshrn-control div:nth-child(1) div {
  color: rgb(51, 51, 51);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
}
/* alterar novo */
.css-pwshrn-control div:nth-child(1) input {
  background: 0px center;
  border: 0px;
  font-size: inherit;
  outline: 0px;
  padding: 0px;
  width: 1px;
  color: transparent;
  left: -100px;
  opacity: 0;
  position: relative;
  transform: scale(0);
}
/* alterar novo */
.css-pwshrn-control div:nth-child(2) {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 0px;
}
/* alterar novo */
.css-pwshrn-control div:nth-child(2) div {
  color: rgb(204, 204, 204);
  display: flex;
  padding: 8px;
  transition: color 150ms ease 0s;
  box-sizing: border-box;
}
.input-wrapper {
  position: relative
}
.input-wrapper > svg:not(.password-icon) {
  stroke: var(--error);
  position: absolute;
  right: 10px;
  top: 19px;
  transition: opacity .2s ease .04s, transform .2s ease
}
.input-wrapper .password-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 50%;
  right: .85rem;
  transform: translateY(-50%);
  padding: 0;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
  opacity: .4
}
/* alterar */
.dsar-form {
  margin: 0 auto;
  width: calc(100% - 24px);
  padding: 40px
}
.pvt-msg-space {
  margin-top: 10px
}
.pvt-magical-space {
  margin-top: 20px;
  margin-bottom: 10px
}
/* alterar */
.pvt-auth-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 652px;
  padding: 40px;
  margin-bottom: 40px;
  background: #fff;
  box-shadow: 0 0 12px rgba(83, 98, 109, .05);
  border-radius: 10px
}
.pvt-password-recovery {
  text-align: center;
  display: table;
  margin: 0 auto;
  color: var(--gray)
}
.custom-ReactPasswordStrength-input {
  background-color: #fff !important;
  padding: 14px 0 5px 14px !important
}
.ReactPasswordStrength-strength-desc {
  font-style: italic;
  font-size: 16;
  position: absolute;
  top: 2px;
  right: 0;
  text-align: right;
  transition: color .25s ease-in-out;
  width: 25%
}
.pvt-header {
  /* position: relative !important; */
  background: var(--surface) !important;
  box-shadow: 0 4px 12px rgba(83, 98, 109, .17) !important;
}
@media (min-width:624px) {
  .pvt-header {
    min-width: 240px !important;
    min-height: 100vh !important;
  }
}
@media (max-width:623px) {
  .pvt-header {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 88px;
    max-height: 88px;
    z-index: 100
  }
  /* alterar */
  .pvt-extended-header {
    height: 68px ;
  }
}
/* alterar remover
@media (max-width:370px) {
  .pvt-extended-header {
    height: 105px;
    max-height: 105px
  }
}
*/
/* alterar */
/* .pvt-navigation {
  padding: 0 0 20px 0 ;
} */
/* alterar */
.pvt-navigation li {
  position: relative;
  margin-bottom: -1px ;
  text-transform: capitalize ;
  font-style: normal ;
  font-weight: bold ;
  font-size: 16px ;
  line-height: 50px ;
  color: var(--txt-surface);
  padding-left: 20px ;
  border-top: 1px solid #F5F5F5 ;
  border-bottom: 1px solid #F5F5F5 ;
}
.pvt-navigation li a {
  text-decoration: none ;
  color: inherit;
  display: block
}
/* alterar novo */
.pvt-navigation li a::after {
  color: var(--grayLight);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f054";
  float: right;
  padding-right: 20px;
}
/* alterar novo */
.pvt-navigation li:hover {
  background: var(--bgColor);
}
/* alterar - remover
.pvt-navigation li:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  width: 6px;
  background: var(--primaryColor);
  transform: scale3d(0, 1, 0);
  transition: transform .3s var(--enter);
  transform-origin: 0 0
}
.pvt-navigation li:hover:before {
  transform: scaleX(.3)
}
*/
/* alterar remover
.pvt-navigation li.active {
  font-weight: 700
}
/* alterar novo */
.pvt-navigation li.active a {
  color: var(--primaryColor);
}
/* alterar */
.pvt-navigation li.active:before {
  transform: scaleX(1)
}
/* @media (max-width:623px) {
  .pvt-navigation {
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 102;
    border-top: 1px solid #efefef;
    background-color: #fff;
    height: 100vh;
    margin-top: 20px
  }
  .pvt-navivation__show-menu {
    visibility: visible
  }
} */
/* alterar */
/* .pvt-user {
  min-width: 100px;
  justify-content: flex-end;
  background-color: white;
  padding: 10px 15px 10px 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: var(--gray)
} */
/* alterar */
/* .pvt-user h5 {
  font-size: 14px;
  margin-right: 10px;
  margin-left: 5px;
  display: inline-table
} */
.pvt-user img {
  margin-left: 1rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  border-radius: 3rem
}
/* alterar */
.pvt-user button {
  color: var(--primaryColor);
  background-color: transparent;
  border: 0px;
  border-left: 1px solid var(--gray);
  cursor: pointer;
  margin-left:10px 
}
.pvt-user .pvt-icon {
  margin-left: .75rem
}
div .pvt-user-btn {
  font-size: .84rem
}
div .pvt-user-btn.pvt-btn-ghost {
  font-weight: 300
}
.pvt-user:hover .pvt-drop-down {
  opacity: 1;
  pointer-events: all
}
.pvt-drop-down {
  position: absolute;
  top: 18px;
  right: 0;
  width: 100%;
  max-width: 100px;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
  z-index: 2
}
.pvt-drop-down:after {
  content: "";
  position: absolute;
  right: 12px;
  top: -3px;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background-position: 0 0;
  border: 4.24264px solid var(--surface);
  transform: rotate(45deg)
}
.pvt-drop-down-content {
  padding: .75rem 1rem;
  background: #fff
}
/* alterar remover
.pvt-drop-down .pvt-logout {
  display: block;
  width: 100%;
  border: 0;
  margin: 0 auto;
  color: var(--surface);
  padding: .5rem 1rem;
  border-radius: 5px;
  background-color: var(--error);
  border-color: rgba(27, 31, 35, .15);
  box-shadow: 0 1px 0 rgba(27, 31, 35, .1), inset 0 1px 0 hsla(0, 0%, 100%, .03);
  cursor: pointer
}
*/
/* .unauthenticated-actions, .user-actions { */
.unauthenticated-actions {
  flex-direction: column;
  align-items: flex-end !important
}
/* .pvt-user, .sign-row { */
.sign-row {
  margin-top: 5px
}
/* .pvt-user {
  min-width: 100px;
  justify-content: flex-end
} */
.AUTO-LOGIN-APP {
  display: none
}
@media (max-width:624px) {
  /* .unauthenticated-actions, .user-actions { */
  .unauthenticated-actions {
    top: 10px;
    right: 10px;
    z-index: 101
  }
}
/* alterar remover
@media (max-width:370px) {
  .sign-row {
    margin-right: 5px;
    flex-direction: column;
    align-items: flex-end !important
  }
  .sign-row a {
    margin-bottom: 5px
  }
} */

.language-container {
  font-size: 13px;
  float: right;
  position: relative;
  z-index: 100;
  text-align: right
}
.language-container .langWidth {
  min-width: 145px
}
.language-container a {
  color: #333;
  text-decoration: none;
  display: inline-block
}
.language-container img {
  margin-left: 5px;
  max-width: 24px
}
.language-option {
  font-size: 12px;
  line-height: 18px;
  float: none;
  display: block !important;
  text-align: right
}
.language-option img {
  margin-left: 5px;
  max-width: 18px
}
.language-option .selected li {
  background-color: #ebeeef
}
.language-options {
  border: 1px solid #5d576f;
  background-color: #fff;
  min-width: 100px;
  padding: 3px;
  right: 0;
  position: absolute;
  display: none
}
.language-options.opened {
  display: block
}
/* alterar */
.pvt-main {
  padding: 0 40px 40px 40px;
  width: 100%;
  position: relative
}
/* alterar */
/* .pvt-main-header {
  padding: 30px 0;
  margin-bottom: 10px;
  display: flow-root;
  position: relative;
  z-index: 100;
} */
/* alterar novo */
/* .pvt-main-header h1 {
  font-size: 26px;
  color: var(--grayMidium);
  padding: 5px 0px 0px;
  margin: 0px;
  width: 60%
} */
.pvt-main > section {
  margin-left: auto;
  margin-right: auto;
  max-width: 840px
}
.pvt-main > section > header {
  margin-bottom: 4rem
}
.pvt-content-header {
  color: var(--gray);
  margin-bottom: 4rem
}
/* alterar */
.pvt-content-title {
  margin: 0 0 5px !important;
  font-size: 1.875rem !important;
  font-weight: bold !important;
  color: var(--txt-surface) !important
}
.pvt-content-desc {
  font-size: 1rem
}
/* alterar remover
.pvt-user-signin .pvt-user-signup {
  margin-right: 1rem
}
*/
/* alterar */
.pvt-user-signin {
  background-color: var(--primaryColor);
  font-weight: bold;
  text-decoration: none;
  margin-right: 1.5rem
}
.pvt-user-signin-access{
    text-decoration: none;
    color: var(--primaryColor);
}
/* alterar */
 /* @media (max-width:624px) {
  .pvt-main-header {
    margin-top: -20px;
    position: fixed;
    right: 20px;
    z-index: 0 !important;
  }
  /* alterar novo 
  .pvt-main-header h1 {
    display: none;
  }
}*/
/* alterar */
@media (max-width:992px) {
  .pvt-main {
    padding: 0 1.5rem 1.5rem;
    overflow-x: scroll
  }
}
/* alterar */
@media (min-width:626px) {
  .pvt-main {
    width: calc(100% - 240px);
    padding-bottom: 70px
  }
}
/* alterar */
/* .pvt-logo {
  display: flex;
  width: auto;
  margin: 40px auto;
  text-align: center;
} */
/* alterar - novo */
/* .pvt-logo img {
  max-width: 140px;
  height: auto;
  margin: 0px auto;
} */
/* alterar */
/* @media (max-width:623px) {
  .pvt-logo {
    padding-left: 12px;
    margin: -40px 0px 0px 50px;
  }
  /* alterar 
  .pvt-logo, .pvt-logo img {
    max-width: 105px;
    max-height: 40px;
    margin-top: -20px
  }
} */
/* alterar novo */
@media (max-width:360px) {
  .pvt-logo {
    padding-left: 12px;
    margin: -40px 0px 0px 50px;
  }
}
/* Alterar - remover
@media (min-width:624px) {
  .pvt-logo {
    position: fixed;
    top: 20px
  }
}
*/
/* alterar novo */
.pvt-card {
  box-shadow: 0px 2px 6px #0000000A;
  border-radius: 10px;
  background-color: var(--bgCard);
  display: block;
  overflow: hidden
}
/* alterar novo */
.pvt-card-empty i {
  color: var(--gray);
  font-size: 50px;
	margin-bottom: 20px
}
/* alterar novo */
.pvt-card-bkg {
  background: transparent linear-gradient(297deg, var(--toggle) 0%, var(--toggleMidium) 100%) 0% 0% no-repeat padding-box;
}
/* alterar novo */
.pvt-card-bkg h2, .pvt-card-bkg p {
  color: var(--surface) !important;
}
/* alterar novo */
.pvt-card-group {
  width: 100%;
  display: table
}
/* alterar novo */
.pvt-img-card-50 {
  background-repeat: no-repeat;
  background-size: cover;
  display: table-cell;
  background-position-x: center;
  width: 50%;
  height: 100%;
}
/* alterar novo */
.pvt-img-card-100 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  display: table-row;
  width: 100%;
  height: 200px;
}
/* alterar novo */
.pvt-txt-card-50 {
  display: table-cell;
  width: 50%;
  height: 100%;
  padding: 40px;
}
.pvt-txt-card {
  display: table-cell;
  width: auto;
  height: auto;
  padding: 40px;
}
/* alterar novo */
.pvt-card h2 {
  font-size: 26px;
  font-weight: bold;
  padding-bottom: 20px;
}
/* alterar novo */
.pvt-card p {
  font-size: 1rem;
  font-weight: normal;
  line-height: 150%;
  color: var(--gray);
  padding-bottom: 10px;
}
/* alterar novo */
.pvt-card-50 {
  width: 48%;
  display: inline-table;
  margin-bottom: 40px
}
/* alterar novo */
.pvt-card-group .pvt-card-50:nth-child(even) {
  margin-left: 4%;
}
/* alterar novo */
@media (max-width:992px) {
  /* alterar novo */
  .pvt-img-card-50 {
    width: 100%;
    height: 200px;
    display: table-row;
  }
  /* alterar novo */
  .pvt-card-50 {
    width: 100%;
    display: inline-table;
  }
  /* alterar novo */
  .pvt-card-group .pvt-card-50:nth-child(even) {
    margin-left: 0%;
  }
}
/* alterar novo */
.pvt-card-100 {
  width: 100%;
  display: table;
  margin-bottom: 40px
}
/* alterar novo */
.pvt-card-icon {
  padding-left: 80px;
}
/* alterar novo */
.pvt-card-icon h2 {
  padding-bottom: 10px;
}
/* alterar novo */
.pvt-card-icon i {
  font-size: 50px;
  position: absolute;
  color: var(--primaryColor);
  margin-left: -80px;
  margin-top: 10px;
}
/* alterar novo */
.pvt-card-buttons {
  display: table-cell;
  vertical-align: middle;
  padding-right: 40px;
  min-width: 270px;
  text-align: right
}
/* alterar novo */
.pvt-card-buttons .pvt-btn, .pvt-card-buttons .pvt-btn2, .pvt-card-buttons .pvt-btn3 {
  display: inline-block
}
@media (max-width:992px) {
  /* alterar novo */
  .pvt-card-icon .pvt-txt-card {
    display: block;
    width: 100%;
  }
  .pvt-card-icon .pvt-card-buttons {
    display: block;
    vertical-align: middle;
    padding: 0px 0px 40px 40px;
    width: 100%;
    text-align: left;
  }
}
.error-message {
  position: absolute;
  right: 8px;
  margin: 4px 0 0;
  z-index: 9;
  color: var(--error);
  font-size: 12px
}
.pvt-backbutton {
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  color: #53626d;
  padding: .5rem 0;
  outline: none;
  cursor: pointer
}
.pvt-backbutton svg {
  position: relative;
  top: 2px;
  height: 18px;
  width: 24px;
  color: #53626d
}
.pvt-downloadbutton {
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  color: #53626d;
  padding: .5rem 0;
  outline: none;
  cursor: pointer
}
.pvt-downloadbutton svg {
  position: relative;
  top: 2px;
  height: 18px;
  width: 24px;
  color: #53626d
}
.pvt-okbutton {
  margin-left: 5rem;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  color: #53626d;
  padding: .5rem 0;
  outline: none;
  cursor: pointer
}
.pvt-okbutton svg {
  margin-right: 10px;
  position: relative;
  top: 2px;
  height: 18px;
  width: 24px;
  color: #060;
  fill: #060
}
.pvt-cancelbutton {
  margin-right: 5rem;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  color: #53626d;
  padding: .5rem 0;
  outline: none;
  cursor: pointer
}
.pvt-cancelbutton svg {
  margin-right: 10px;
  position: relative;
  top: 2px;
  height: 18px;
  width: 24px;
  color: #600;
  fill: #600
}
.pvt-your-request .pvt-backbutton {
  margin-right: 1.8rem;
  padding: .5rem 1.8rem .5rem 0;
  border-right: 1px solid rgba(83, 98, 109, .3)
}
.dsar-field {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 2px solid var(--grayLight) !important;
  outline: none;
  font-size: 18px !important;
  color: var(--txt-surface) !important
}
.dsar-field.error:focus + svg, .dsar-field.error:valid + svg {
  opacity: 0;
  transform: scale3d(.75, .75, 0)
}
/* alterar */
.dsar-field.error:focus:not(.error) {
  box-shadow: 0 10px 20px -13px rgba(32, 56, 117, .35);
  border-color: var(--primaryLight)
}
.grid {
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -8px
}
@media (min-width:540px) {
  .grid {
    display: flex
  }
}
.grid.grid-nogutter {
  margin: 0
}
.grid.grid-nogutter > .col {
  padding: 0
}
.col {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  min-width: 0;
  padding: 0 8px
}
.col-align-top {
  align-self: flex-start
}
.col-align-bottom {
  align-self: flex-end
}
.col-align-middle {
  align-self: center
}
.col-top {
  justify-content: flex-start !important
}
.col-bottom, .col-top {
  flex-direction: column;
  display: flex
}
.col-bottom {
  justify-content: flex-end !important
}
.col-middle {
  justify-content: center;
  flex-direction: column;
  display: flex
}
.grid-start {
  justify-content: flex-start
}
.grid-center {
  justify-content: center
}
.grid-end {
  justify-content: flex-end
}
.grid-around {
  justify-content: space-around
}
.grid-between {
  justify-content: space-between
}
.col-first {
  order: -1
}
.col-last {
  order: 1
}
.grid-reverse {
  flex-direction: row-reverse
}
.col-fixed {
  flex: initial
}
.col-grow-2 {
  flex-grow: 2
}
.col-grow-3 {
  flex-grow: 3
}
.col-grow-4 {
  flex-grow: 4
}
.col-grow-5 {
  flex-grow: 5
}
.col-grow-6 {
  flex-grow: 6
}
.col-grow-7 {
  flex-grow: 7
}
.col-grow-8 {
  flex-grow: 8
}
.col-grow-9 {
  flex-grow: 9
}
.col-grow-10 {
  flex-grow: 10
}
.col-grow-11 {
  flex-grow: 11
}
@media (min-width:540px) {
  .col-1 {
    flex-basis: 8.33333%;
    max-width: 8.33333%
  }
}
@media (max-width:540px) {
  .col-1 {
    flex-basis: 16.66667%;
    max-width: 16.66667%
  }
}
@media (min-width:540px) {
  .col-2 {
    flex-basis: 16.66667%;
    max-width: 16.66667%
  }
}
@media (max-width:540px) {
  .col-2 {
    flex-basis: 33.33333%;
    max-width: 33.33333%
  }
}
@media (min-width:540px) {
  .col-3 {
    flex-basis: 25%;
    max-width: 25%
  }
}
@media (max-width:540px) {
  .col-3 {
    flex-basis: 50%;
    max-width: 50%
  }
}
@media (min-width:540px) {
  .col-4 {
    flex-basis: 33.33333%;
    max-width: 33.33333%
  }
}
@media (max-width:540px) {
  .col-4 {
    flex-basis: 66.66667%;
    max-width: 66.66667%
  }
}
@media (min-width:540px) {
  .col-5 {
    flex-basis: 41.66667%;
    max-width: 41.66667%
  }
}
@media (max-width:540px) {
  .col-5 {
    flex-basis: 83.33333%;
    max-width: 83.33333%
  }
}
@media (min-width:540px) {
  .col-6 {
    flex-basis: 50%;
    max-width: 50%
  }
}
@media (max-width:540px) {
  .col-6 {
    flex-basis: 100%;
    max-width: 100%
  }
}
@media (min-width:540px) {
  .col-7 {
    flex-basis: 58.33333%;
    max-width: 58.33333%
  }
}
@media (max-width:540px) {
  .col-7 {
    flex-basis: 116.66667%;
    max-width: 116.66667%
  }
}
@media (min-width:540px) {
  .col-8 {
    flex-basis: 66.66667%;
    max-width: 66.66667%
  }
}
@media (max-width:540px) {
  .col-8 {
    flex-basis: 133.33333%;
    max-width: 133.33333%
  }
}
@media (min-width:540px) {
  .col-9 {
    flex-basis: 75%;
    max-width: 75%
  }
}
@media (max-width:540px) {
  .col-9 {
    flex-basis: 150%;
    max-width: 150%
  }
}
@media (min-width:540px) {
  .col-10 {
    flex-basis: 83.33333%;
    max-width: 83.33333%
  }
}
@media (max-width:540px) {
  .col-10 {
    flex-basis: 166.66667%;
    max-width: 166.66667%
  }
}
@media (min-width:540px) {
  .col-11 {
    flex-basis: 91.66667%;
    max-width: 91.66667%
  }
}
@media (max-width:540px) {
  .col-11 {
    flex-basis: 183.33333%;
    max-width: 183.33333%
  }
}
@media (min-width:540px) {
  .col-12 {
    flex-basis: 100%;
    max-width: 100%
  }
}
@media (max-width:540px) {
  .col-12 {
    flex-basis: 200%;
    max-width: 200%
  }
}
@media only screen and (max-width:540px) {
  .col-sm {
    flex: 100% 1;
    max-width: 100%
  }
}
@media only screen and (max-width:624px) {
  .col-md {
    flex: 100% 1;
    max-width: 100%
  }
}
@media only screen and (max-width:744px) {
  .col-lg {
    flex: 100% 1;
    max-width: 100%
  }
}
.success-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 540px;
  padding-bottom: 36px
}
.svg-box {
  display: inline-block;
  position: relative;
  width: 150px
}
.green-stroke {
  stroke: #7cb342
}
.checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  stroke-width: 6px
}
.red-stroke {
  stroke: #ff6245
}
.yellow-stroke {
  stroke: #ffc107
}
.circular circle.path {
  stroke-dasharray: 330;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  opacity: .4;
  -webkit-animation: draw-circle .7s ease-out;
  animation: draw-circle .7s ease-out
}
/* alterar */
/* .pvt-responsive-menu-button {
  width: 38px;
  height: 38px;
  margin-left: 15px;
  vertical-align: top;
  margin-top: 15px;
  border: none;
  background-color: #fff
} */
/* .pvt-responsive-menu-button:hover {
  cursor: pointer;
  background-color: #ccc
} */
/* .pvt-responsive-menu-button img {
  vertical-align: middle
} */
@media (min-width:624px) {
  .pvt-responsive-menu-button {
    display: none
  }
}
/* alterar novo */
footer {
  background-color: #828b96;
  color: var(--surface);
  width: calc(100%);
  height: 60px;
  bottom: 0px;
  padding: 0px 40px 0px 40px;
  margin-left: 0px;
  margin-top: -63px;
  position: static
}
/* alterar novo */
footer .copy, footer .privacy-ass {
  display: table-column;
  width: 50%;
  line-height: 60px;
  vertical-align: middle;
}
/* alterar novo */
footer .copy {
  text-align: left;
  float: left
}
/* alterar novo */
footer .copy p {
  line-height: 60px;
  vertical-align: middle;
}
/* alterar novo */
footer .privacy-ass {
  text-align: right;
  float: right
}
/* alterar novo */
footer .privacy-ass a {
  color: #C8D4D8;
  text-decoration: none;
  font-size: 12px;
  vertical-align: middle
}
/* alterar novo */
footer .privacy-ass img {
  vertical-align: middle;
  margin-left: 5px
}
/* alterar novo */
@media (max-width:624px) {
  footer {
    margin-left: 0px;
    margin-top: 0px;
    width: 100%
  }
}
