.custom-ReactPasswordStrength-input {
    background-color: white !important;
    padding: 14px 0 5px 14px !important; }

.ReactPasswordStrength-strength-desc {
    font-style: italic;
    padding: 6px 12px;
    position: absolute;
    top: 1px;
    right: 0;
    text-align: right;
    transition: color 250ms ease-in-out;
    width: 15%; }
