.pvt-accordion {
    position: relative;
    border-top: 1px solid rgba(83, 98, 109, 0.14);

    &:first-of-type {
        border-top: none; }

    &-header {
        position: relative;
        padding: 0.75rem 0;
        color: var(--gray);
        cursor: pointer;
        display: flex;
        align-items: center;
        &.toggled {
            i {
                font-weight: bold; } }
        > svg {
            margin: 4px 8px 0 -4px;
            height: 20px;
            width: 25px; } }

    &-content {
        padding: 0 0 0 2rem;
        color: var(--gray);
        box-sizing: border-box;
        overflow: hidden;
        p {
            margin: 0 0 1rem;
            font-size: 0.75rem;
            font-style: italic;
            line-height: 1.4em; } } }

.pvt-accordion-header,
.pvt-accordion-content {
    .pvt-switch {
        position: absolute;
        top: 0.75rem;
        right: 0; } }
