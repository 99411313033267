@import '../common';
.pvt-main-root {
    width: calc(100% - 240px); }

.pvt-main {
    padding: 0 40px 40px 40px;
    width: 100%;
    min-height: 100%;
    position: relative;
    overflow-x: auto;

    &-header {
        width: 100%;
        padding: 30px 0;
        margin-bottom: 10px;
        display: flow-root;
        position: relative;
        z-index: 100;
        h1 {
            font-size: 26px;
            color: var(--grayMidium);
            padding: 5px 0px 0px;
            margin: 0px;
            width: 60%; }
        .pvt-row {
           // width: 100%
           //user-actions
           //   max-width: fit-content
 } }           //   width: 40%

    > section {
        margin-left: auto;
        margin-right: auto;
        max-width: 840px;
        > header {
            margin-bottom: 4rem; } } }

.pvt-content-header {
    color: var(--gray);
    margin-bottom: 4rem; }

.pvt-content-title {
    margin: 0 0 1rem 0;
    font-size: calc(var(--base) * 2px + 4px); }

.pvt-content-desc {
    font-size: 1rem; }

.pvt-user-signup,
.pvt-user-signin {
    margin-left: 1rem; }

.pvt-user-signup {
    color: var(--gray);
    text-decoration: underline; }

.pvt-user-signin {}

@media(max-width: $screen-md) {
    .pvt-main-root {
        width: 100%; }

    .pvt-main {
        min-height: calc(100% - 4rem);
        padding-top: 6.5rem !important; }

    .pvt-main-header {
        display: flex !important;
        align-items: center !important;
        justify-content: flex-end;
        width: fit-content;
        max-width: calc(100% - 0.5rem - 38px - 105px - 0.5rem);
        height: 5.5rem !important;
        padding: 0.5rem !important;
        margin: 0 !important;
        position: fixed !important;
        right: 0;
        top: 0;
        h1 {
            display: none; }
        .pvt-row {
           // height: 100%
           //ax-width: 100%
           //user-actions
           //   height: 100%
           //   width: 100%
           //   justify-content: space-around
           //   .pvt-row
           //       height: 100%
 } }           //       max-width: 100%

    // .pvt-content-header
    //     margin-bottom: 1rem
    // .pvt-route-content
 }    //     margin-top: 7rem

@media(max-width: $screen-lg) {
    .pvt-main {
        padding: 0 1.5rem 1.5rem; }

    .pvt-route-content.unauthenticated {
        margin-top: 92px; } }

@media(min-width: 626px) {
    .pvt-main {
        width: calc(100%); } }
