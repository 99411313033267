.pvt-alert {
    font-size: 0.75rem;
    line-height: 1.4em;
    color: var(--color);
    display: flex;
    align-items: center;
    background: var(--background);
    border-radius: 4px;
    padding: .5rem;
    max-width: 440px;
    > p {
        margin: 0;
        font-style: normal; }

    &-error {
        --background: #ff5555;
        --color: #fff;
        margin-bottom: 12px;
        font-size: 14px;
        padding-left: 1em;
        padding-right: 1em;
        line-height: 1.2em; }

    &-success {
        --background: #d4edda;
        --color: #155724;
        margin-bottom: 12px;
        font-size: 14px;
        padding-left: 1em;
        padding-right: 1em;
        line-height: 1.2em; }

    &-disclaimer {
        --color: #6DA2E2;
        --background: #E4EFFE; }
    &-warning {
        --color: #FEA728;
        --background: #F7EEE3; }
    &-confirm {
        --color: #1DC4C1;
        --background: #C5EDEE; }
    &-full {
        max-width: 100%; }
    &-center {
        text-align: center;
        p {
            margin: 0 auto; } }

    .pvt-accordion-content & {
        margin-bottom: 1rem; }

    svg {
        position: relative;
        margin-right: 4px;
        color: inherit;
        height: 20px;
        width: 20px; } }
