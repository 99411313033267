.pvt-auth-wrapper {
    margin: 0 auto;
    padding: 4rem 8vmin;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(83, 98, 109, 0.05);
    border-radius: 2px; }

.pvt-password-recovery {
    text-align: center;
    display: table;
    margin: 0 auto;
    color: var(--gray); }
