.confirm-redirect {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0,0,0,.6);
}

.confirm-redirect-row {
  display: flex;
  justify-items: left;
  align-items: center;
  text-align: center;
  width: 100%;

  p {
    padding-bottom: 0 !important;
  }

  span {
    font-weight: bold;
    color: var(--primaryColor);
  }
}

.confirm-redirect-buttons-container {
  max-width: 320px;
  display: flex;
  float: right;
  gap: 16px;

  button {
    width: 100%;
  }

  button.confirm-redirect-cancel-button {
    background-color: transparent;
    box-shadow: none;
    color: var(--primaryColor);
  }
}

@media (max-width: 1000px) {
  .confirm-redirect-buttons-container {
    float: inherit;
    margin: 0 auto;
  }
}

@media (max-width: 744px) {
  .popup-content.confirm-redirect-container-content {
    width: 100% !important;
    margin-top: auto !important;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 440px) {
  .confirm-redirect-buttons-container {
    flex-direction: column-reverse;
  }
}
