@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

:root {
  --bgColorOriginal: #f8f8fb;
  --grayOriginal: #53626d;
  --bgColor: #f8f8fb;
  --surface: #fff;
  --gray: #53626d;
  --primaryColor: #259cd3;
  --enter: cubic-bezier(0, 0.5, 0.5, 1);
  --move: cubic-bezier(0.5, 0, 0.5, 1);
  --exit: cubic-bezier(0.5, 0, 0, 1);
  --dk-surface: #53626d;
  --dk-surface-75: rgba(37, 56, 71, 0.76);
  --dk-background-05: rgba(83, 98, 109, 0.05);
  --lg-surface: #fff;
  --lg-surface-grayish: #eaecee;
  --toggle: #37cd8f;
  --confirm: #37cd8f;
  --base: 16;
  --confirmColor: #29cc97;
  --primary: #259cd3;
  --primary-light: #3d9cff;
  --txt-surface: #1a3b5d;
  --error: #f42623;
  --text-dark_background: #fff;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

#privacy-tools-app {
  background: var(--bgColor);
  min-height: 100vh;
  min-width: 100%;
  display: flex;
}

.creteria-pass{
  margin-bottom: 15px;
  width: 70%;
  color: grey;
  /* opacity: 1; */
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}
