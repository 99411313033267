@import '../common';

.pvt-logo {
    display: flex;
    margin: 40px auto;
    text-align: center;
    width: auto;
    height: auto;
    max-width: 240px;
    max-height: 200px;
    padding: 0 0.5em 0.8em 0.5em;

    img {
        max-width: 140px;
        height: auto;
        margin: 0px auto; } }

@media(max-width: $screen-sm) {
    .pvt-logo {
        padding-left: 12px;
        max-width: 105px;
        max-height: 45px;

        img {
            max-height: 100%;
            max-width: 100%; } } }

@media (max-width:623px) {
    .pvt-logo {
        width: 105px;
        height: 40px;
        padding: 0;
        margin: 0;

        img {
            max-height: 100%;
            max-width: 100%; } } }
