.pvt-notlogged {
    padding: 2rem;
    background: linear-gradient(180deg, #53626D 0%, #404548 100%);
    border-radius: 4px;
    color: var(--surface);
    text-align: center;
    max-width: 460px;
    &-wrapper {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
        // background-image: url('/notlogged.png')
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        max-width: 740px; }
    h3 {
        font-size: 2rem;
        margin-bottom: 1rem; }
    .pvt-row {
        margin-top: 2rem;
        button {
            margin: 0 1rem; } } }
