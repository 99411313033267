.pvt-preference {
    padding: 1rem;
    margin-bottom: 24px;
    &:nth-child(even) {
        // border-radius: 4px
 }        // background: rgba(83, 98, 109, 0.03)
    h4 {
        font-size: 18px;
        font-weight: 300;
        margin: 0 0 12px;
        color: var(--gray); }
    .pvt-accordion {
        margin: 0 0.5rem; }

    .pvt-card {
        box-shadow: 0px 2px 6px #0000000A;
        border-radius: 10px;
        background-color: var(--bgCard);
        display: block;
        overflow: hidden;
        &:empty i {
            color: var(--gray);
            font-size: 50px;
            margin-bottom: 20px; } } }

.consent-title {
    margin-right: 40px;
    display: block; }

@media only screen and (max-width: 782px) {
    .title-hold {
        max-width: 6em; }
    .btn-hold {
        margin-top: 5em; } }
