.dsar-label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 300;
    color: var(--txt-surface);
    width: 100%;
    display: block;
    user-select: none; }

.dsar-mandatory {
    &:after {
        content: " *";
        color: red; } }
