.list-datashare {
  min-width: 50rem;
}

.list-row-datashare {
  padding: 1rem 0;
  border-bottom: 1px solid #e5e7eb;
}

.list-cell-datashare {
  color: #707683;
  display: flex;
  align-items: center;
}

.list-header-datashare {
  display: flex;
  align-items: center;
  color: #4D4F5C;
  font-weight: bold;
}

@media (max-width: 624px) {
  .list-datashare {
    padding: 1.5rem 2rem 2rem !important;
    min-width: 35rem;
  }

  .list-row-datashare {
    padding: 0.5rem 0;
    border-bottom: 1px solid #e5e7eb;
  }

  .list-header-datashare {
    width: 25% !important;
    padding: 0 0.5rem 0 0;
  }

  .list-cell-datashare {
    width: 25% !important;
    padding: 0 0.5rem 0 0;

    span, button {
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
    }

    button {
      padding: 0.4rem 0.7rem;
    }
  }
}
