.dsar-form {
    margin: 0 auto;
    width: calc(80% - 800px);
    padding: 40px; }

.pvt-msg-space {
    margin-top: 10px; }

.pvt-magical-space {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-right: 60%; }

.pvt-card-100 {
    width: 80%;
    display: table;
    margin-bottom: 40px; }

#labelAssetType {
    width: 25c; }

#labelName {
    width: 55ch; }

#reason {
    text-shadow: 0 0 black; }

#responsible {
    text-shadow: 0 0 black; }
