@import '../common';

.pvt-navigation {
  // padding: 2em 0 24px
  padding: 0 0 20px 0;
  width: 240px;

  li {
    // position: relative
    // margin-bottom: 12px
    // text-transform: capitalize
    // font-style: normal
    // font-weight: 400
    // font-size: 16px
    // line-height: 26px
    // color: var(--gray)
    // padding-left: 24px

    position: relative;
    margin-bottom: -1px;
    text-transform: capitalize;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 50px;
    color: var(--txt-surface);
    padding-left: 20px;
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #F5F5F5;

    a {
      text-decoration: none;
      color: inherit;
      display: block; }

    &:before {
      // position: absolute
      // left: 0
      // top: 0
      // height: 100%
      // content: ''
      // width: 6px
      // background: var(--primaryColor)
      // transform: scale3d(0, 1, 0)
      // transition: transform 0.3s var(--enter)
 }      // transform-origin: 0 0

    &:hover:before {
      transform: scale3d(0.3, 1, 1); }

    &.active {
      color: var(--primaryColor);

      &:before {
        // transform: scale3d(1, 1, 1)
        transform: scaleX(1);

        &:after {
          color: var(--grayLight);
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f054";
          float: right;
          padding-right: 20px;

          &:hover {
            background: var(--bgColor); } } } } } }

@media(max-width: 623px) {
  .pvt-navigation {
    position: fixed;
    top: 5.5rem;
    left: 0px;
    visibility: hidden;
    z-index: 102;
    border-top: 1px solid #efefef;
    background-color: #fff;
    height: 100vh;
 }    // margin-top: 20px

  .pvt-navivation__show-menu {
    visibility: visible; } }
