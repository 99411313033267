@import '../common';

.pvt-header {}
//     position: relative
//     background: var(--surface)
//     // padding: 2.5vmax 0
//     box-shadow: 0px 4px 12px rgba(83, 98, 109, 0.17)
//  position: relative !important
//  background: var(--surface) !important
//  box-shadow: 0 4px 12px rgba(83, 98, 109, .17) !important

@media(min-width: $screen-md) {
    .pvt-header {
        // display: flex
        // flex-wrap: wrap
        // flex-direction: column
        min-width: 240px;
        min-height: 100vh; } }

@media(max-width: 623px) {
    .pvt-header {
        padding: 0.5rem;
        position: fixed;
        top: 0;
        left: 0;
        min-width: 100%;
        height: 5.5rem;
        max-height: 5.5rem;
        z-index: 100;
        display: flex;
        align-items: center; }

    .pvt-extended-header {
        // height: 98px
 } }        // max-height: 98px

// @media(max-width: 370px)
//     .pvt-extended-header
//         height: 105px
//         max-height: 105px
