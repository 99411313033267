.pvt-txt-card {
    display: table-cell;
    width: auto;
    height: auto;
    padding: 40px !important; }

.pvt-btn {
    display: inline  !important; }


.pvt-btn2 {
    display: inline  !important; }


.pvt-img-banner {
    background-image: url('../../img/banner.png'); }


.pvt-img-photo1 {
    background-image: url('../../img/photo1.png'); }


.pvt-img-photo2 {
    background-image: url('../../img/photo2.png'); }

.pvt-img-protected {
    background-image: url('../../img/protected.png'); }
