//Base unit used for spacing gutters
$base-unit: 8px;

//Screen Sizes
$screen-vvsm: 280px;
$screen-vsm: 340px;
$screen-sm: 540px;
$screen-md: 624px;
$screen-lg: 782px;

.error-message {
  position: absolute;
  right: 8px;
  margin: 4px 0 0;
  z-index: 9;
  color: var(--error);
  font-size: 12px; }

.pvt-backbutton {
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  color: #53626D;
  padding: 0.5rem 0;
  outline: none;
  cursor: pointer;

  svg {
    position: relative;
    top: 2px;
    height: 18px;
    width: 24px;
    color: #53626D; } }

.pvt-downloadbutton {
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  color: #53626D;
  padding: 0.5rem 0;
  outline: none;
  cursor: pointer;

  svg {
    position: relative;
    top: 2px;
    height: 18px;
    width: 24px;
    color: #53626D; } }

.pvt-okbutton {
  margin-left: 05rem;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  color: #53626D;
  padding: 0.5rem 0;
  outline: none;
  cursor: pointer;

  svg {
    margin-right: 10px;
    position: relative;
    top: 2px;
    height: 18px;
    width: 24px;
    color: #006600;
    fill: #006600; } }

.pvt-okbutton-right {
  margin-right: 05rem; }

.singup-recaptcha {
  overflow-x: auto; }

.pvt-cancelbutton {
  margin-right: 05rem;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  color: #53626D;
  padding: 0.5rem 0;
  outline: none;
  cursor: pointer;

  svg {
    margin-right: 10px;
    position: relative;
    top: 2px;
    height: 18px;
    width: 24px;
    color: #660000;
    fill: #660000; } }

.pvt-cancelbutton-left {
  margin-left: 05rem; }

.pvt-your-request {
  .pvt-backbutton {
    margin-right: 1.8rem;
    padding: 0.5rem 1.8rem 0.5rem 0;
    border-right: 1px solid rgba(83, 98, 109, 0.3); } }

.dsar-field {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #ced6e0;
  outline: none;
  font-size: 18px;
  color: var(--txt-surface);
  &.error {
    &:focus + svg,
    &:valid + svg {
      opacity: 0;
      transform: scale3d(0.75, 0.75, 0); }
    &:focus:not(.error) {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35); }
    &:focus:not(.error) {
      border-color: var(--primary-light); } } }

.grid {
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 ($base-unit * -1) 0 ($base-unit * -1);
  @media (min-width: $screen-sm) {
    display: flex; }

  &.grid-nogutter {
    margin: 0;

    > .col {
      padding: 0; } } }

.col {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  min-width: 0;
  padding: 0 $base-unit 0 $base-unit; }

.col-align-top {
  align-self: flex-start; }

.col-align-bottom {
  align-self: flex-end; }

.col-align-middle {
  align-self: center; }

.col-top {
  justify-content: flex-start !important;
  flex-direction: column;
  display: flex; }

.col-bottom {
  justify-content: flex-end !important;
  flex-direction: column;
  display: flex; }

.col-middle {
  justify-content: center;
  flex-direction: column;
  display: flex; }

.grid-start {
  justify-content: flex-start; }

.grid-center {
  justify-content: center; }

.grid-end {
  justify-content: flex-end; }

.grid-around {
  justify-content: space-around; }

.grid-between {
  justify-content: space-between; }

.col-first {
  order: -1; }

.col-last {
  order: 1; }

.grid-reverse {
  flex-direction: row-reverse; }

.col-fixed {
  flex: initial; }

.combo-paises .css-pwshrn-control {
  outline: none;
  transition: all 0.3s ease-in-out;
  padding: 0px 12px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #ced6e0;
  font-size: 18px;
  color: var(--txt-surface);

  &:hover {
    border: 1px solid #ced6e0; } }

.combo-paises.error .css-pwshrn-control {
  border-color: rgba(234, 76, 137, 0.4);
  box-shadow: 0 0 0 4px rgba(234, 76, 137, 0.1); }

//Grow Based Columns
@for $i from 2 through 11 {
  .col-grow-#{$i} {
    flex-grow: $i; } }

@for $i from 1 through 12 {
  .col-#{$i} {
    @media (min-width: $screen-sm) {
      flex-basis: (100% / 12) * $i;
      max-width: (100% / 12) * $i; }
    @media (max-width: $screen-sm) {
      flex-basis: (100% / 6) * $i;
      max-width: (100% / 6) * $i; } } }

@media only screen and (max-width: $screen-md) {
  .col-md {
    flex: 100%;
    max-width: 100%; } }

.txt-side-title {
  font-size: 25px; }

.gridjs-td {
  font-size: 11px; }
@media only screen and (max-width: $screen-lg) {
  .col-lg {
    flex: 100%;
    max-width: 100%; } }

// Abimated icons
.success-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 540px;
  padding-bottom: 36px; }
.consent-title {
  margin-right: 0.5em;
  max-width: 100%; }
.txt-side-title {
  font-size: 25px; }

.gridjs-td {
  font-size: 11px; }

.svg-box {
  display: inline-block;
  position: relative;
  width: 150px; }

.pvt-request-detail {
  .pvt-row {
    display: flex; } }

.green-stroke {
  stroke: #7CB342; }

.checkmark {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  stroke-width: 6px; }

.red-stroke {
  stroke: #FF6245; }

.yellow-stroke {
  stroke: #FFC107; }

.circular circle.path {
  stroke-dasharray: 330;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  opacity: 0.4;
  animation: 0.7s draw-circle ease-out; }

/*------- Checkmark --------- */

.checkmark {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 56px;
  left: 49px;
  width: 52px;
  height: 40px; }

path {
  animation: 1s draw-check ease-out; }

@keyframes draw-circle {
  0% {
    stroke-dasharray: 0,330;
    stroke-dashoffset: 0;
    opacity: 1; }

  80% {
    stroke-dasharray: 330,330;
    stroke-dashoffset: 0;
    opacity: 1; }

  100% {
    opacity: 0.4; } }

@keyframes draw-check {
  0% {
    stroke-dasharray: 49,80;
    stroke-dashoffset: 48;
    opacity: 0; }

  50% {
    stroke-dasharray: 49,80;
    stroke-dashoffset: 48;
    opacity: 1; }

  100% {
    stroke-dasharray: 130,80;
    stroke-dashoffset: 48; } }

/*---------- Cross ---------- */

.cross {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 54px;
  left: 54px;
  width: 40px;
  height: 40px; }

.first-line {
  animation: 0.7s draw-first-line ease-out; }

.second-line {
  animation: 0.7s draw-second-line ease-out; }

@keyframes draw-first-line {
  0% {
    stroke-dasharray: 0,56;
    stroke-dashoffset: 0; }

  50% {
    stroke-dasharray: 0,56;
    stroke-dashoffset: 0; }

  100% {
    stroke-dasharray: 56,330;
    stroke-dashoffset: 0; } }

@keyframes draw-second-line {
  0% {
    stroke-dasharray: 0,55;
    stroke-dashoffset: 1; }

  50% {
    stroke-dasharray: 0,55;
    stroke-dashoffset: 1; }

  100% {
    stroke-dasharray: 55,0;
    stroke-dashoffset: 70; } }

.alert-sign {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 40px;
  left: 68px;
  width: 15px;
  height: 70px;
  animation: 0.5s alert-sign-bounce cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.dot {
  stroke: none;
  fill: #FFC107; }

@keyframes alert-sign-bounce {
  0% {
    transform: scale(0);
    opacity: 0; }

  50% {
    transform: scale(0);
    opacity: 1; }

  100% {
    transform: scale(1); } }

@media only screen and (max-width: $screen-sm) {
  .col-sm {
    flex: 100%;
    max-width: 100%; }

  .pvt-card-100 {
    display: block; }

  .pvt-switch-text {
    margin-bottom: -34px; }

  .consent-title {
    word-break: break-word;
    max-width: 10em; }

  .pvt-preference {
    padding: 10%; }

  .dsar-form {
    padding: 10%; }

  .pvt-request-detail {
    .pvt-row {
      display: flex; } }

  .txt-side-title {
    font-size: 12px;
    word-break: break-word; }

  .gridjs-td {
    font-size: 11px; }

  .pvt-txt-card {
    word-break: break-word; } }

@media only screen and (max-width: $screen-vsm) {
  .gridjs-td {
    font-size: 11px; }

  .dsar-form {
    padding: 5%; }

  .pvt-request-detail {
    .pvt-row {
      display: flex; } }

  .consent-title {
    word-break: break-word;
    max-width: 5em; }

  .pvt-preference {
    padding: 5%; }

  .txt-side-title {
    font-size: 12px;
    word-break: break-word; }

  .pvt-txt-card {
    word-break: break-word; }

  .pvt-user {
    h5 {
      font-size: 11px; } } }

@media only screen and (max-width: $screen-vvsm) {
  .pvt-user {
    width: 60%; }
  .sign-row {
    margin-right: -20px; } }
@media only screen and (min-width: $screen-lg) {
  .gridjs-td {
    font-size: 16px; } }
