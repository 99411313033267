.pvt-btn {
    display: flex;
    align-items: center;
    padding: 0.75em 1em;
    border: none;
    background: var(--background);
    border-radius: 0.5rem;
    color: var(--color);
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    transition: 0.05s box-shadow var(--move);
    outline: none;
    text-decoration: none;
    &:disabled {
        pointer-events: none;
        opacity: 0.3; }
    &:hover {
        transform: translate3d(0, -1px, 0); }
    &:active {
        transform: translate3d(0, 1px, 0); }
    &-primary {
        --color: var(--surface);
        --background: var(--primaryColor); }
    &-default {
        --color: var(--surface);
        --background: var(--primaryColor); }
    &-confirm {
        --color: var(--surface);
        --background: var(--confirmColor); }
    &-cancel {
        --color: #fff;
        --background: #6c757d; }
    &-danger {
        --color: var(--surface);
        --background: var(--error); }
    &-ghost {
        --color: var(--gray);
        --background: transparent;
        text-decoration: underline; }
    &.lighter {
        --color: var(--surface);
        font-weight: 300; }
    &.invert {
        svg {
            margin-right: 0;
            margin-left: 0.75rem;
            order: 2; } }
    &.full {
        display: block;
        padding: 1rem;
        width: 100%;
        text-align: center; }
    &.size {
        &-small {
            font-size: 0.8rem; } }

    svg {
        margin-right: 0.75rem;
        height: 24px;
        width: 24px;
        opacity: 0.2;
        color: black; } }
