@import '../common';

$dropdown-width: 100px;
$gray: #828b96;

.pvt-user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    min-width: 100px;
    margin-top: 1rem;
    background-color: white;
    padding: 0.6rem 1rem;
    -webkit-border-radius: 0.6rem;
    -moz-border-radius: 0.6rem;
    color: $gray;
    h5 {
        font-size: 0.9rem;
        // display: inline-table
        margin-left: 0.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block; }

    img {
        margin-left: 1rem;
        max-height: 2.5rem;
        max-width: 2.5rem;
        border-radius: 3rem; }
    .pvt-icon {
        margin-left: 0.75rem; } }

div .pvt-user-btn {
    font-size: 0.84rem;
    &.pvt-btn-ghost {
        font-weight: 300; } }

.pvt-user:hover {
    .pvt-drop-down {
        opacity: 1;
        pointer-events: all; } }

.pvt-drop-down {
    position: absolute;
    top: 18px;
    right: 0px;
    width: 100%;
    max-width: $dropdown-width;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    z-index: 2;
    &:after {
        content: '';
        position: absolute;
        right: 12px;
        top: -3px;
        z-index: 1;
        display: block;
        width: 8.48528137px;
        height: 8.48528137px;
        background-position: 0 0;
        border: solid 4.24264069px var(--surface);
        transform: rotate(45deg); }

    &-content {
        padding: 0.75rem 1rem;
        background: white; }
    .pvt-logout {
        display: block;
        width: 100%;
        border: 0;
        margin: 0 auto;
        color: var(--surface);
        padding: 0.5rem 1rem;
        border-radius: 5px;
        background-color: var(--error);
        border-color: rgba(27,31,35,.15);
        box-shadow: 0 1px 0 rgba(27,31,35,.1), inset 0 1px 0 hsla(0,0%,100%,.03);
        cursor: pointer; } }

.user-actions, .unauthenticated-actions {
    flex-direction: column;
    align-items: flex-end !important; }

.sign-row {
    margin-top: 5px; }

.AUTO-LOGIN-APP {
    display: none; }

@media(max-width: $screen-md) {
    .pvt-user {
        padding: 0;
        margin: 0;
        max-width: 100%;
        // h5
 }        //     display: block

    .unauthenticated-actions {
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 101; } }

@media(max-width: 370px) {
    .sign-row {
        margin-right: 5px;
        flex-direction: column {
 }         // align-items: flex-end !important

        a {
            margin-bottom: 5px; } } }
