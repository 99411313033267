@import '../common';

.pvt-responsive-menu-button {
    width: 38px;
    height: 38px;
    // margin-left: 15px
    vertical-align: top;
    // margin-top: 5px
    border: none;
    background-color: #fff;

    &:hover {
        cursor: pointer;
        background-color: #ccc; }

    img {
        vertical-align: middle; } }

@media(min-width: $screen-md) {
    .pvt-responsive-menu-button {
        display: none; } }
