.pvt {
    &-switch {
        position: relative;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        --travel: 15px;
        --text: 0.75em;
        --base: 16px;
        .pvt-accordion-header & {
            margin: 0; }
        &-large {
            --travel: 18px;
            --base: 19px;
            --text: 0.75em;
            .pvt-switch-text {
                font-style: italic; } }
        &-text {
            font-weight: 700;
            color: var(--dk-surface);
            font-size: var(--text);
            margin-right: 8px;
            cursor: pointer;
            user-select: none; }
        &-checkbox {
            display: none;
            &:checked {
                + label:before {
                    transform: translate3d(var(--travel), 1px, 0);
                    background: var(--toggle); }
                + label:active {
                    &:before {
                        transform-origin: top right;
                        transform: translate3d(var(--travel), 1px, 0) scale3d(1.2, 1, 1); } } } }
        &-label {
            position: relative;
            top: 1px;
            height: calc(var(--base) + 2px);
            width: calc(var(--base) * 2);
            display: block;
            border-radius: calc(var(--travel) + 4px);
            border: 1px solid var(--dk-surface);
            cursor: pointer;
            &:before {
                position: absolute;
                transform: translate3d(1px, 1px, 0);
                content: "";
                width: calc(var(--base) - 2px);
                height: calc(var(--base) - 2px);
                border-radius: 100%;
                background-color: var(--dk-surface);
                transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1), background 0.3s cubic-bezier(0, 0, 0.2, 1); }
            &:active {
                &:before {
                    transform-origin: top left;
                    transform: translate3d(1px, 1px, 0) scale3d(1.2, 1, 1); } } } } }
