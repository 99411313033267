.popup-content {
  max-height: fit-content !important;
  // width: auto !important;
}

.popup {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem !important;
  align-items: center;
  color: rgba(0,0,0,.6);

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    justify-self: left;
    list-style: disc;
    margin: 0 2rem;
  }

  .popup-confirm-button {
    margin-top: 1rem;
  }

  @media (max-width: 744px) {
    h1 {
      margin-bottom: 0.8rem;
    }
    p {
      margin: 0;
    }
    p, span, button {
      font-size: 0.8rem;
    }
  }
}

.popup-row {
  display: flex;
  justify-items: left;
  align-items: center;
  height: 5rem;
  width: 100%;
  padding: 0 2rem;
  img {
    padding: 0;
    margin: 0;
    height: 3rem;
  }

  @media (max-width: 744px) {
    height: auto;
  }
}

.popup-redirect {
  .popup-logo {
    justify-content: center;
  }

  .popup-text {
    p {
      color: rgba(0,0,0,.5);
      margin-bottom: 1rem;
    }
  }

  .img-bank-container {
    border: solid 1px rgba(0,0,0,.1);
    border-radius: 0.3rem;
    font-size: 1.3rem;
  }

  .popup-arrow-container {
    svg {
      height: 3rem;
      width: 3rem;
    }

    p {
      color: rgba(0,0,0,.3);
      font-size: 0.8rem !important;
      text-align: left;
      width: 100%;
    }
  }
}

.popup-error {
  h1 {
    font-size: 1.2rem;
  }

  .popup-row {
    padding: 0;
    p {
      text-align: left;
    }
  }

  @media (max-width: 744px) {
    h1 {
      font-size: 0.9rem;
    }
  }
}

.popup-consent {
  p, span {
    margin: 0 0 1rem 0;
    padding: 0;
    text-align: left;
  }

  button {
    margin: 0 0.5rem;
  }

  .popup-row-buttons {
    justify-content: center;
  }

  @media (max-width: 744px) {
    h1 {
      font-size: 1rem;
    }
    p, span {
      margin: 0 0 0.5rem;
    }
  }
}