.pvt-label {
    display: block;
    width: fit-content;
    text-align: center;
    background: var(--background);
    color: var(--color);
    padding: 0.3rem 1rem;
    border-radius: 30px;
    &-concluded {
        --background: #29CC97;
        --color: var(--surface); }
    &-opened {
        --background: #1aff1a;
        --color: var(--surface); }
    &-pendent {
        --background: #FEC400;
        --color: var(--surface); }
    &-denied {
        --background: #f8d7da;
        --color: #721c24; } }


