@media (max-width: 768px) {
  h1.view-detail-header-title {
    font-size: 20px !important;
  }
}

@media (max-width: 624px) {
  .view-detail-header-back-button {
    display: none !important;
  }
}
