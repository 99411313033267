.dsar-button-loading {
    width: 180px;
    height: 55px;
    padding-top: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center; }

.dsar-button {
    width: 180px;
    height: 55px;
    background: var(--confirmColor);
    border: none;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    // box-shadow: 0 10px 20px rgba(35, 100, 210, 0.3)
    color: #fff;
    cursor: pointer;
    outline: none;
    transition: 0.05s box-shadow var(--move);
    font-family: inherit;
    &:hover {
        transform: translate3d(0, -1px, 0);
 }        // box-shadow: 0 10px 20px rgba(35, 100, 210, 0.4)
    &:active {
        transform: translate3d(0, 1px, 0);
 } }        // box-shadow: 0 0 0 rgba(35, 100, 210, 1)
