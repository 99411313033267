@import '../common';

.pvt-row {
    display: flex;

    &.x-center {
        justify-content: center; }
    &.x-end {
        justify-content: flex-end; }
    &.x-between {
        justify-content: space-between; }
    &.y-start {
        align-items: flex-start; }
    &.y-center {
        align-items: center; }

    > h1 {
        margin: 0;
        font-size: 1.8em; } }

.pvt-col {
    display: block; }

@media(max-width: $screen-md) {
    .pvt-row {
        font-size: 12px; }

    .pvt-col {
        font-size: 12px; } }
