.language-container {
  font-size: 13px;
  float: right;
  position: relative;
  z-index: 100;
  text-align: right;

  .langWidth {
    display: flex;
    align-items: center;
    min-width: 145px; }
  a {
    color: #333;
    text-decoration: none;
    display: inline-block; }

  img {
    margin-left: 5px;
    max-width: 24px; } }

.language-option {
  font-size: 12px;
  line-height: 18px;
  float: none;
  display: block !important;
  text-align: right;

  img {
    margin-left: 5px;
    max-width: 18px; } }

.language-option {
  .selected {
    li {
      background-color: #ebeeef; } } }

.language-options {
  border: 1px solid #5D576F;
  background-color: #fff;
  min-width: 100px;
  padding: 3px;
  right: 0px;
  position: absolute;
  display: none; }

.language-options.opened {
  display: block; }
